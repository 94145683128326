import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useFormState } from 'react-use-form-state';

import api from 'src/explore/services/api';
import { ErrorBox } from 'src/explore/elements/bs4/ErrorBox';
import { FormStateInput } from 'src/explore/elements/FormStateInput';
import { LoadingButton } from 'src/shoppe/elements/LoadingButton';
import { Modal } from 'src/shoppe/elements/Modal';

import styles from './index.module.sass';

type INotificationForm = {
  email: string;
  subscribe: boolean;
};

interface NotificationFormProps {
  overlayClass?: string;
  open: boolean;
  productID: number;
  showSubscribeCheckbox?: boolean;
  onClose: () => void;
}

export const NotificationForm = ({
  open,
  overlayClass,
  productID,
  showSubscribeCheckbox = true,
  onClose,
}: NotificationFormProps ) => {
  const [ emailRef, setEmailRef ] = React.useState<HTMLInputElement | null>( null );
  const [ error, setError ] = useState<string[]>( null );
  const [ loading, setLoading ] = useState<boolean>( false );
  const [ success, setSuccess ] = useState<boolean>( false );

  const [ formState, { email, checkbox }] = useFormState<INotificationForm>({
    subscribe: true,
  });

  const handleClose = () => {
    setTimeout(() => setSuccess( false ), 201 );
    onClose();
  };

  const handleSubmit = () => {
    if ( !formState.values.email || formState.errors.email ) {
      return setError( formState.errors.email ? [ formState.errors.email ] : null );
    }

    setError( null );
    setLoading( true );

    return api
      .post( `products/${productID}/waitlists`, {
        email: formState.values.email,
        email_opt_in: showSubscribeCheckbox ? formState.values.subscribe : false,
      })
      .then(() => {
        emailRef?.blur();
        setSuccess( true );
      })
      .catch(() => {
        setError([ 'Sorry, there was an error. Please try again.' ]);
      })
      .finally(() => {
        setLoading( false );
      });
  };

  useEffect(() => {
    if ( !success ) {
      emailRef?.focus();
    }
  }, [ emailRef, success ]);

  return (
    <Modal
      closeBtn
      overlayClass={overlayClass}
      containerClass={cls(
        `${styles.container} w-100 pt-5 pb-7 pb-md-8 border-0 text-center overflow-hidden spec__notify-me-modal`
      )}
      open={open}
      onClick={handleClose}
    >
      <FontAwesomeIcon className="text-dark fs-5 fs-md-10" icon={faEnvelope} />
      <h2 className="px-8 mt-1 fs-5 fs-md-8">Notify Me When This Item is Available</h2>

      {!!error && <ErrorBox className="mt-4 mt-md-7" errors={error} />}

      <div className="row">
        <div className="col-12 form-group pb-0">
          <FormStateInput
            className="mt-4 mt-md-7 fs-5"
            {...email( 'email' )}
            aria-label="Email Address"
            placeholder="Enter Your Email Address"
            ref={( elementRef ) => setEmailRef( elementRef )}
            required
            onKeyDown={( e: React.KeyboardEvent<HTMLInputElement> ) => {
              if ( e.keyCode === 13 ) {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
        </div>
      </div>

      {showSubscribeCheckbox && (
        <div className="row mt-2">
          <div className="col-12 form-group d-flex justify-content-center pb-0">
            <label
              className={`${styles.subscribe} d-flex justify-content-center align-items-start align-items-sm-center font-weight-normal fs-4 text-left text-md-center`}
              htmlFor="marketing-subscription"
            >
              <FormStateInput
                className="d-none"
                id="marketing-subscription"
                {...checkbox( 'subscribe' )}
              />
              <div className={`${styles.checkbox} position-relative flex-shrink-0 mr-2`}>
                <FontAwesomeIcon
                  className={`${styles.check} position-absolute m-auto fs-5`}
                  icon={faCheck}
                />
              </div>
              Keep me up to date on the latest news and exclusive promotions.
            </label>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-6 form-group pb-0 mb-0">
          <button
            type="button"
            className="btn btn-block py-2 text-secondary font-weight-bold"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
        <div className="col-6 form-group pb-0 mb-0">
          <LoadingButton
            className="btn btn-block btn-secondary py-2 font-weight-bold"
            disabled={!formState.values.email}
            loading={loading}
            type="button"
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </div>
      </div>

      {success && (
        <div
          className={cls(
            styles.success,
            'position-absolute d-flex flex-column justify-content-center align-items-center p-8 bg-white',
            {
              [styles.show]: success,
            }
          )}
        >
          <FontAwesomeIcon
            className={`${styles.thanks} text-secondary fs-5 fs-md-10`}
            icon={faCheckCircle}
          />
          <h2 className="mt-1 text-secondary fs-5 fs-md-8">Thank You</h2>
          <p className={styles.confirmation}>
            We will email you as soon as this product is available
          </p>
        </div>
      )}
    </Modal>
  );
};
